import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/lib/locale/es'
import colors from 'vuetify/lib/util/colors'

import { Intersect, Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Intersect,
    Ripple,
  },
})

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },

  theme: {
    themes: {
      light: {
        newGrey: '#e7ebee',
        newBlue: '#bbdefb',
        primary: colors.blue.accent3,
        secondary: colors.lightBlue.base,
        accent: colors.indigo.base,
        info: colors.blue.darken1,
        success: colors.green.base,
        lightenSuccess: colors.green.accent1,
        error: colors.red.darken1,
        lightenError: colors.red.accent1,
        warning: colors.amber.darken2,
        companyGreen: '#00C389',
        visitaPropia: '#1D8348',
      },
    },
  },
})

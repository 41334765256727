import axios from 'axios'

export default axios.create({
  baseURL: '/api',
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
    'Django-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
})

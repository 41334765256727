const state = {
  filters: {
    card__is_shelved: false,
  },
}

const actions = {
  async addFilter({ commit }, { filter, value }) {
    commit('addFilter', { filter, value })
  },
  async removeFilter({ commit }, filter) {
    commit('removeFilter', filter)
  },
  async setFilters({ commit }, filters) {
    commit('setFilters', filters)
  },
}

const mutations = {
  setFilters(state, filters) {
    if (JSON.stringify(filters) === JSON.stringify(state.filters)) return
    state.filters = { ...filters }
  },
  addFilter(state, { filter, value }) {
    state.filters = { ...state.filters, [filter]: value }
  },
  removeFilter(state, filter) {
    const f = { ...state.filters }
    delete f[filter]
    state.filters = f
  },
}

export default {
  state,
  actions,
  mutations,
}

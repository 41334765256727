import api from '@/api'
import { defaultPaths } from '@/assets/constants'
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/common_files',
      name: 'common_files',
      component: () => import('@/pages/common/CommonFiles'),
    },
    {
      path: '/',
      name: 'root',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/common/Login'),
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/admin/Admin'),
      meta: { position: 'admin' },
      children: [
        {
          path: 'ficha/:id',
          name: 'admin-ficha',
          component: () => import('@/views/common/components/AdminFicha.vue'),
          meta: { position: 'admin' },
        },

        {
          path: 'base_de_datos',
          name: 'admin-base',
          component: () => import('@/views/common/GoodTable'),
          meta: { position: 'admin' },
        },
        {
          path: 'bbdd_errores',
          name: 'admin-errors',
          component: () => import('@/pages/admin/validation/ValidationDashboard.vue'),
          meta: { position: 'admin' },
        },
        {
          path: 'bbdd_errores_tabla',
          name: 'admin-errors-table',
          component: () => import('@/pages/admin/validation/ValidationErrorsList.vue'),
          meta: { position: 'admin' },
        },
        {
          path: 'semanales',
          name: 'admin-semanales',
          component: () => import('@/pages/admin/NewAnalytic'),
          meta: { position: 'admin' },
        },
        {
          path: 'scripts',
          name: 'admin-scripts',
          component: () => import('@/pages/admin/OperatorSpeech'),
          meta: { position: 'admin' },
        },
        {
          path: 'info',
          name: 'admin-info',
          component: () => import('@/pages/admin/ServiceInfo'),
          meta: { position: 'admin', requiresPIN: true },
        },
        {
          path: 'mensajes',
          name: 'admin-mensajes',
          component: () => import('@/pages/admin/AdminNotys'),
          meta: { position: 'admin' },
        },
        {
          path: 'tickets',
          name: 'admin-tickets',
          component: () => import('@/pages/admin/tickets/AdminTickets'),
          meta: { position: 'admin' },
        },
        {
          path: 'cambios',
          name: 'admin-changes',
          component: () => import('@/pages/admin/HistoryChanges'),
          meta: { position: 'admin' },
        },
        {
          path: 'personal',
          name: 'admin-staff',
          component: () => import('@/pages/admin/AdminPersonal'),
          meta: { position: 'admin' },
        },
        {
          path: encodeURI('análisis'),
          name: 'admin-anal-status',
          component: () => import('@/pages/admin/AnalStatus'),
          meta: { position: 'admin' },
        },
        {
          path: 'llamadas',
          name: 'admin-calls',
          component: () => import('@/pages/admin/CallsAdmin'),
          meta: { position: 'admin' },
        },
        {
          path: 'crm/dashboard',
          name: 'crm-dashboard',
          component: () => import('@/pages/admin/crm/Dashboard'),
          meta: { position: 'admin' },
        },
        {
          path: 'crm/contratacion',
          name: 'crm-contratacion',
          component: () => import('@/pages/admin/crm/Contratacion'),
          meta: { position: 'admin' },
        },
        {
          path: 'crm/liquidacion',
          name: 'crm-liquidacion',
          component: () => import('@/pages/admin/crm/Contratacion'),
          meta: { position: 'admin', liquidacion: true },
        },
        {
          path: 'crm/papellera',
          name: 'crm-papellera',
          component: () => import('@/pages/admin/crm/CrmPapelera'),
          meta: { position: 'admin' },
        },
        {
          path: 'crm/reporte',
          name: 'crm-report',
          component: () => import('@/pages/admin/crm/CrmReport'),
          meta: { position: 'admin' },
        },
        {
          path: 'crm/estudios',
          name: 'crm-estudios',
          component: () => import('@/pages/admin/crm/estudio/EstudioList.vue'),
          meta: { position: 'admin' },
        },
        {
          path: 'crm/futuras_ofertas',
          name: 'crm-futuras-ofertas',
          component: () => import('@/pages/admin/crm/CrmFutureOffers'),
          meta: { position: 'admin' },
        },
        {
          path: 'crm/notas',
          name: 'crm-notas',
          component: () => import('@/pages/admin/crm/notes/CrmNotes.vue'),
          meta: { position: 'admin' },
        },
        {
          path: 'internal/rentabilidad',
          name: 'admin-contracts-rentabilidad',
          component: () => import('@/views/admin/components/administration/ContractsRentabilidad'),
          meta: { requiresPIN: true, position: 'admin' },
        },
        {
          path: 'internal/contabilidad_ingeniumgroup',
          name: 'crm-accounting1',
          component: () => import('@/pages/admin/crm/accounting/CrmAccounting'),
          meta: { position: 'admin', company: 'ingeniumgroup', requiresPIN: true },
        },
        {
          path: 'internal/contabilidad_gestiongroup',
          name: 'crm-accounting2',
          component: () => import('@/pages/admin/crm/accounting/CrmAccounting'),
          meta: { position: 'admin', company: 'gestiongroup', requiresPIN: true },
        },
        {
          path: 'internal/contabilidad_mienergiatuenergia',
          name: 'crm-accounting3',
          component: () => import('@/pages/admin/crm/accounting/CrmAccounting'),
          meta: { position: 'admin', company: 'mienergiatuenergia' },
        },
        {
          path: 'solar_dashboard',
          name: 'solar_dashboard',
          component: () => import('@/views/solar/SolarDashboard'),
          meta: { position: 'admin' },
        },
        {
          path: 'solar_admin',
          name: 'solar_admin_general',
          component: () => import('@/views/admin/components/solar/SolarModule'),
          meta: { position: 'admin' },
        },
        {
          path: 'solar_stock',
          name: 'solar_stock',
          component: () => import('@/views/admin/components/solar/SolarStock'),
          meta: { position: 'admin' },
        },
        {
          path: 'new_solar_stock',
          name: 'new_solar_stock',
          component: () => import('@/views/admin/components/solar/NewSolarStock'),
          meta: { position: 'admin' },
        },
        {
          path: 'solar_contabilidad',
          name: 'solar_contabilidad',
          component: () => import('@/views/admin/components/solar/SolarContabilidad'),
          meta: { position: 'admin' },
        },
        {
          path: 'usuarios_predictivo',
          name: 'predictive_users',
          component: () => import('@/views/admin/components/stuff/PredictiveUsers'),
          meta: { position: 'admin' },
        },
        {
          path: 'sips',
          name: 'sips',
          component: () => import('@/pages/admin/sips/SipsList.vue'),
          meta: { position: 'admin' },
        },
        {
          path: 'tareas_sips',
          name: 'sips_tasks',
          component: () => import('@/pages/admin/sips/SipsTasks.vue'),
        },
        {
          path: 'sips_analisis',
          name: 'sips_dashboard',
          component: () => import('@/pages/admin/sips/analytic/SipsDashboard.vue'),
          meta: { position: 'admin' },
        },
        {
          path: 'sips_mapa',
          name: 'sips_map',
          component: () => import('@/pages/admin/sips/analytic/SipsAgentsMap.vue'),
          meta: { position: 'admin' },
        },
        {
          path: 'agenda',
          name: 'agenda',
          component: () => import('@/views/agenda/AgendaList.vue'),
        },
        {
          path: 'new_agenda',
          name: 'agenda-new',
          component: () => import('@/views/agenda/CrudAgenda.vue'),
        },
        {
          path: 'agenda/:id',
          name: 'agenda-detail',
          component: () => import('@/views/agenda/CrudAgenda.vue'),
        },
      ],
    },
    {
      path: '/admin/load',
      name: 'admin-load',
      component: () => import('@/views/admin/components/base/ImportData.vue'),
      meta: { position: 'admin' },
    },
    {
      path: '/operator',
      name: 'operator',
      component: () => import('@/views/operator/Operator'),
      meta: { position: 'operator' },
    },
    {
      path: '/manager',
      name: 'manager',
      component: () => import('@/views/manager/Manager'),
      meta: { position: 'manager' },
      children: [
        {
          path: 'tickets',
          name: 'agente-tickets',
          component: () => import('@/views/tickets/MyTickets'),
        },
        {
          path: 'tareas',
          name: 'agente-tareas',
          component: () => import('@/pages/agente/tareas.vue'),
          meta: { position: 'manager' },
        },
        {
          path: 'ficha/:cardId',
          name: 'agent-card',
          component: () => import('@/pages/agente/ficha.vue'),
          meta: { position: 'manager' },
        },
        {
          path: 'reuniones',
          name: 'agente-reuniones',
          component: () => import('@/pages/agente/reuniones'),
          meta: { position: 'manager' },
        },
        {
          path: 'agenda',
          name: 'agente-agenda',
          component: () => import('@/views/manager/components/ManagerAgenda.vue'),
          meta: { position: 'manager' },
        },
        {
          path: 'comparador',
          name: 'agente-comparador',
          component: () => import('@/pages/agente/comparador'),
          meta: { position: 'manager' },
        },
        {
          path: 'nuevo_suministro',
          name: 'agente-nuevo_suministro',
          component: () => import('@/pages/agente/nuevo_suministro'),
          meta: { position: 'manager' },
        },
        {
          path: 'info',
          name: 'agente-info',
          component: () => import('@/pages/agente/info'),
          meta: { position: 'manager' },
        },
        {
          path: 'perfil',
          name: 'agente-perfil',
          component: () => import('@/pages/agente/perfil'),
          meta: { position: 'manager' },
        },
        {
          path: 'contratacion',
          name: 'agent-my-contracts',
          component: () => import('@/pages/admin/crm/Contratacion'),
          meta: { position: 'manager' },
        },
        {
          path: 'dashboard_agente',
          name: 'agent-dashboard',
          component: () => import('@/views/manager/components/AgentsDashboard'),
          meta: { position: 'manager' },
        },
        {
          path: 'fichas_captador',
          name: 'agent-my-fichas-en2',
          component: () => import('@/views/solar/SolarFichasCaptador'),
          meta: { position: 'manager' },
        },
        {
          path: 'sips',
          name: 'agent-sips',
          component: () => import('@/pages/admin/sips/SipsList'),
          meta: { position: 'manager' },
        },
        {
          path: 'tareas_sips',
          name: 'agent-tasks-sips',
          component: () => import('@/pages/agente/tareas_sips.vue'),
          meta: { position: 'manager' },
        },
        {
          path: 'tareas_sips/:id',
          name: 'agent-task-sips',
          component: () => import('@/pages/agente/[id].vue'),
        },
        {
          path: 'estudios',
          name: 'estudios',
          component: () => import('@/pages/admin/crm/estudio/EstudioList.vue'),
        },
        {
          path: 'cartera_cups',
          name: 'cartera_cups',
          component: () => import('@/pages/admin/analytic/CarteraCups.vue'),
        },
        {
          name: 'analisis_resp_comerc',
          path: 'analisis_resp_comerc',
          component: () => import('@/pages/agente/analisis_resp_comerc.vue'),
        },
        {
          name: 'mapa_resp_comerc',
          path: 'mapa_resp_comerc',
          component: () => import('@/pages/agente/mapa_resp_comerc.vue'),
        },
      ],
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/common/profile/UserProfile'),
    },
    {
      path: '/speech/:id',
      name: 'speech',
      component: () => import('@/views/admin/components/SpeechView'),
      meta: { position: 'admin' },
    },
    {
      path: '/solar_installer',
      name: 'solar_installer',
      component: () => import('@/views/solar/SolarInstaller'),
      meta: { position: 'solar_installer' },
      children: [
        {
          path: 'agenda',
          name: 'solar-agenda',
          component: () => import('@/views/solar/SolarAgenda'),
          meta: { position: 'solar_installer' },
        },
        {
          path: 'tareas',
          name: 'solar-tareas',
          component: () => import('@/views/solar/SolarTareas'),
          meta: { position: 'solar_installer' },
        },
        {
          path: 'stock',
          name: 'agent-solar-stock',
          component: () => import('@/views/admin/components/solar/SolarStock'),
          meta: { position: 'solar_installer' },
        },
        {
          path: 'tickets',
          name: 'solar-tickets',
          component: () => import('@/views/tickets/MyTickets'),
          meta: { position: 'solar_installer' },
        },
      ],
    },

    {
      path: '/televenta_admin',
      name: 'televenta_admin',
      component: () => import('@/views/sm/SalemanagerAdmin.vue'),
      meta: { position: 'salemanager_admin' },
      children: [
        {
          path: 'reuniones',
          name: 'salemanager-admin-reuniones',
          component: () => import('@/pages/agente/reuniones'),
        },
        {
          path: 'tareas',
          name: 'sm-tareas',
          component: () => import('@/pages/agente/tareas'),
        },
        {
          path: 'tickets',
          name: 'sm_tickets',
          component: () => import('@/views/tickets/MyTickets'),
          meta: { position: 'salemanager_admin' },
        },
        {
          path: 'personal',
          name: 'sm_personal',
          component: () => import('@/pages/admin/AdminPersonal'),
          meta: { position: 'salemanager_admin' },
        },
        {
          path: 'profile',
          name: 'sm-profile',
          component: () => import('@/views/sm/SmProfile.vue'),
        },
        {
          path: 'contratacion',
          name: 'sm-contracts',
          component: () => import('@/views/sm/SmContracts'),
        },
        {
          path: 'nuevo_suministro',
          name: 'sm-nuevo_suministro',
          component: () => import('@/pages/agente/nuevo_suministro'),
        },
      ],
    },
    {
      path: '/televenta_agente',
      name: 'televenta_agente',
      component: () => import('@/views/manager/Manager'),
      meta: { position: 'salemanager' },
      children: [
        {
          path: 'tickets',
          name: 'salemanager-tickets',
          component: () => import('@/views/tickets/MyTickets'),
        },
        {
          path: 'tareas',
          name: 'salemanager-tareas',
          component: () => import('@/pages/agente/tareas'),
        },
        {
          path: 'reuniones',
          name: 'salemanager-reuniones',
          component: () => import('@/pages/agente/reuniones'),
        },
        {
          path: 'nuevo_suministro',
          name: 'salemanager-nuevo_suministro',
          component: () => import('@/pages/agente/nuevo_suministro'),
        },
        {
          path: 'info',
          name: 'salemanager-info',
          component: () => import('@/pages/agente/info'),
        },
        {
          path: 'perfil',
          name: 'salemanager-perfil',
          component: () => import('@/pages/agente/perfil'),
        },
        {
          path: 'contratacion',
          name: 'salemanager-my-contracts',
          component: () => import('@/pages/admin/crm/Contratacion'),
        },
        // {
        //   path: 'fichas_captador',
        //   name: 'salemanager-my-fichas-en2',
        //   component: () => import('@/views/solar/SolarFichasCaptador'),
        // },
      ],
    },
  ],
})

async function handlePin() {
  let { pin } = store.state.auth
  if (!pin) {
    const { value } = await Vue.swal({
      title: 'PIN requerido',
      input: 'password',
      inputAttributes: { autocomplete: 'off', autocapitalize: 'off', autocorrect: 'off', name: 'pin' },
    })
    pin = value
  }

  const pinIsOk = (await api.post('/check_pin/', { pin })).data === 'OK'

  if (pinIsOk) store.commit('setPIN', pin)
  else Vue.swal({ title: 'PIN incorrecto. Permiso denegado', icon: 'error' })

  return pinIsOk
}

router.beforeEach((to, from, next) => {
  let { isAuthorised, position } = store.getters
  const { position: toPosition, requiresPIN } = to.meta

  // TODO: blyat
  if (position === 'salemanager_admin') position = 'manager'

  if (isAuthorised) {
    if (toPosition && toPosition !== position) {
      Vue.toasted.error(`Permiso denegado`, { duration: 3000 })
      return
    } else if (['login', 'root'].includes(to.name)) {
      next({ path: defaultPaths[position] || '/' + position })
    } else {
      if (!requiresPIN) {
        next()
        return
      }
      handlePin().then(pinIsOk => {
        if (pinIsOk) next()
        else next(from.path)
      })
    }
  } else {
    if (to.name === 'login') {
      next()
    } else {
      next({ name: 'login' })
    }
  }
})

export default router

import usersService from '@/api/services/usersService'

const state = {
  users: [],
}

const getters = {
  tramitacion: state => {
    return state.users.filter(user => user.position === 'tramitacion')
  },
  admins: state => {
    return state.users.filter(user => user.position === 'admin')
  },
  managers: state => {
    return state.users.filter(user => user.position === 'manager')
  },
  salemanagers: state => {
    return state.users.filter(user => user.position === 'salemanager')
  },
  operators: state => {
    return state.users.filter(user => user.position === 'operator')
  },
  installers: state => {
    return state.users.filter(user => user.position === 'solar_installer')
  },
  operatorsAndManagers: state => {
    return state.users.filter(user => ['operator', 'manager'].indexOf(user.position) !== -1).sort()
  },
  salemanager_admins: state => {
    return state.users.filter(({ position }) => position === 'salemanager_admin')
  },
  getFullName: state => userId => {
    return (state.users.find(user => parseInt(user.id) === parseInt(userId)) || { fullname: '' }).fullname
  },
  users: state => state.users,
}

const actions = {
  async fetchUsers({ commit }) {
    commit('setUsers', await usersService.fetchUsers())
  },
}

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}

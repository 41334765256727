import api from '@/api'
import { onlyUnique } from '@/assets/constants'
import vuetify from '../../plugins/vuetify'

const state = {
  collapsedCards: [],
  codigos: [],
  info: [],
  states: [],
  isDark: false,
  companies: [],
  newVersionForManager: false,
  savedTables: [],
  sipsFilters: {},
  newCardForm: {},
  crmOffers: [],
  crmOffersLoading: false,
}

const actions = {
  async fetchCrmOffers({ commit, state }) {
    if (state.crmOffers.length || state.crmOffersLoading) return
    commit('setCrmOffersLoading', true)
    try {
      const { data } = await api.get('/comparador/offers_crm/')
      commit('setCrmOffers', data)
    } finally {
      commit('setCrmOffersLoading', false)
    }
  },

  async fetchCompanies({ commit }) {
    const { data: companies } = await api.get('/comparador/companies/')
    commit('setCompanies', companies)
  },
  async fetchCodigos({ commit }) {
    const { data } = await api.get('/cards/codigos/')
    commit('setCodigos', data)
  },
  async fetchInfo({ commit }) {
    const { data } = await api.get('/service_info/')
    commit('setInfo', data)
  },
}

const mutations = {
  setCrmOffers(state, offers) {
    state.crmOffers = offers
  },
  setCrmOffersLoading(state, loading) {
    state.crmOffersLoading = loading
  },
  setNewCardForm(state, newCardForm) {
    state.newCardForm = newCardForm
  },
  setSipsFilters(state, filters) {
    state.sipsFilters = { ...filters }
  },
  saveTable(state, { name, table }) {
    let st = state.savedTables
    if (!Array.isArray(st)) st = []
    state.savedTables = [...st, { name, table }]
  },
  deleteTable(state, nameToDelete) {
    state.savedTables = state.savedTables.filter(({ name }) => name !== nameToDelete)
  },
  setNewVersionForManager(state, v) {
    state.newVersionForManager = v
  },
  setStates(state, states) {
    state.states = [...states]
  },
  setCompanies(state, companies) {
    state.companies = companies
  },
  toggleTheme(state) {
    state.isDark = !state.isDark
    vuetify.framework.theme.isDark = state.isDark
  },
  collapseCard(state, card) {
    if (state.collapsedCards.find(c => c.id === card.id)) return
    const cards = state.collapsedCards
    cards.push(card)
    state.collapsedCards = cards
  },
  uncollapseCard(state, cardId) {
    state.collapsedCards = state.collapsedCards.filter(c => c.id !== cardId)
  },
  setCodigos(state, codigos) {
    state.codigos = codigos
  },
  setInfo(state, info) {
    state.info = info
  },
}

const getters = {
  postalcodes: state => state.codigos,
  companies: state => state.companies,
}

export default {
  state,
  actions,
  mutations,
  getters,
}

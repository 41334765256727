import api from '..'

export default {
  async fetchSipLogins() {
    const { data } = await api.get('/users_v2/sip_logins/')
    return data
  },
  async freeHours(dt, managerId) {
    const { data } = await api.get(`/users/free_hours/?dt=${dt}&manager_id=${managerId}`)
    return data
  },
  async fetchUsers() {
    return (await api.get('/users/'))?.data || []
  },
  async deleteUser(userId) {
    await api.delete(`/users_v2/${userId}/`)
  },
  async getChanges(query) {
    const { data } = await api.get(`/changes/?${query}`)
    return data
  },
  async getChangesPaginated(query) {
    const { data } = await api.get(`/paginated_changes/?${query}`)
    return data
  },
}

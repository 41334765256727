const state = () => ({
  calculatedOffers: [],
  calculatorForm: {
    kind: 'luz',
    igic: false,
    client_type: 1,
  },
})

const actions = {
  setComparadorForm({ commit }, form) {
    commit('setCalculatorForm', form)
  },
}

const mutations = {
  resetCalculator(state) {
    state.calculatorForm = { client_type: 1, kind: 'luz', igic: false }
  },
  setCalculatedOffers(state, offers) {
    state.calculatedOffers = offers
  },
  setCalculatorForm(state, form) {
    state.calculatorForm = form
  },
}
export default {
  namespaced: false,
  state,
  mutations,
  actions,
}

<template>
  <v-snackbar bottom right :timeout="-1" v-model="snackbar">
    <v-row align="center">
      <v-col> {{ progressTitle }} </v-col>

      <v-col cols="auto">
        <v-btn icon color="error" @click="progress = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-linear :value="progress" height="25">
          {{ progress }}
          {{ Number.isInteger(progress) ? '%' : '' }}
        </v-progress-linear>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: 'WebsocketProgressBar',
  data() {
    return {
      progressTitle: null,
      progress: null,
    }
  },
  computed: {
    snackbar() {
      return this.progress !== null
    },
  },
  mounted() {
    this.$options.sockets.onmessage = ({ data: response }) => {
      response = JSON.parse(response)
      const { type } = response
      if (type === 'progress') {
        const { data } = response
        if (data.stop) {
          this.onStop()
        } else {
          const { title, progress } = data
          if (!this.progressTitle) this.progressTitle = title
          this.progress = progress
        }
      }
    }
  },
  methods: {
    onStop() {
      this.progressTitle = null
      this.progress = null
    },
  },
}
</script>

import { defaultPaths } from '@/assets/constants'
import * as LogRocket from 'logrocket'
import api from '../../api'
import authService from '../../api/services/authService'
import router from '../../router'

const getDefaultState = () => {
  return {
    loggedIn: false,
    jwt: '',
    fullname: '',
    userId: 0,
    profile: {
      using_predictive: null,
      using_auto_predictive: null,
    },
    allowed: true,
    restoreToken: null,
    iceServers: null,
    pin: null,
  }
}
const state = getDefaultState()
const actions = {
  async checkAccess({ commit }) {
    const { data } = await api.get('/is_call_allowed')
    const { allowed } = data
    commit('setAllowed', allowed)
  },
  async executeLogin({ commit, dispatch }, { creds, token }) {
    if (!token) token = await authService.obtainToken(creds)
    commit('updateToken', token)
    const position = await dispatch('updateProfile')
    const newPath = defaultPaths[position] || '/' + position
    if (newPath !== router.history.current.path) await router.push(newPath)
    return token
  },
  async updateProfile({ commit, state, dispatch }) {
    try {
      const { token } = await authService.refreshToken(state.jwt)
      commit('updateToken', token)
      const profile = await authService.getProfile()
      const shouldLog = process.env.NODE_ENV === 'production' && profile.id !== 461
      if (shouldLog) {
        LogRocket.init('fvn8vp/cv')
        LogRocket.identify(profile.id, { name: profile.fullname, email: profile.email })
      }

      if (profile.position === 'operator') await dispatch('checkAccess')
      commit('updateProfile', profile)
      return profile.position
    } catch (e) {
      if (JSON.stringify(e?.response?.data || '').includes('expired')) {
        await dispatch('executeLogout')
        return
      }
      throw new Error(e?.response?.data || e)
    }
  },
  executeLogout({ commit }) {
    localStorage.removeItem('vuex')
    commit('logout')
    router.push('/login').catch(() => {})
  },
  async fetchIceServers({ commit, state }) {
    const { metered_api_key: API_KEY } = state.profile
    const response = await fetch(`https://call-visit.metered.live/api/v1/turn/credentials?apiKey=${API_KEY}`)
    const iceServers = await response.json()
    if (iceServers.error) throw new Error(iceServers.error)
    commit('storeIceServers', iceServers)
  },
}

const mutations = {
  storeIceServers(state, iceServers) {
    state.iceServers = iceServers
  },
  logout(state) {
    Object.assign(state, getDefaultState())
  },
  setRestoreToken(state, token) {
    state.restoreToken = token
  },
  setAllowed(state, allowed) {
    state.allowed = allowed
  },
  updateToken(state, newToken) {
    if (state.jwt !== newToken) state.jwt = newToken
    api.defaults.headers.Authorization = `Bearer ${newToken}`
  },
  updateProfile(state, profile) {
    state.profile = { ...profile }
    state.fullname = profile.fullname
    state.userId = profile.id
    state.loggedIn = true
  },
  setPIN(state, pin) {
    state.pin = pin
  },
}

const getters = {
  isAuthorised: state => state.loggedIn,
  usingPredictive: state => state.profile.using_predictive,
  usingAutoPredictive: state => state.profile.using_auto_predictive,
  fullname: state => state.fullname,
  profileId: state => state.profile.id,
  position: state => state.profile.position,
  icon: state => state.profile.icon || 'mdi-account-circle',
  isAdmin: state => state.profile.position === 'admin',
  isSmAdmin: state => state.profile.position === 'salemanager_admin',
  isChief: state => state.profile.chief,
  isOperator: state => state.profile.position === 'operator',
  isManager: state => state.profile.position === 'manager',
  isInstaller: state => state.profile.position === 'solar_installer',
  sensibleDataPermission: state => state.profile.is_superuser,
  canEditChats: state => state.profile.can_edit_chats,
  hasSipsAccess: state => state.profile.has_sips_access,
  can_see_observation: state => state.profile.can_see_observation,
  can_edit_phone_notes: state => state.profile.can_edit_phone_notes,
  can_edit_persona_contacto: state => state.profile.can_edit_persona_contacto,
  can_edit_name: state => state.profile.can_edit_name,
  can_view_report: state => state.profile.can_view_report,
  alt_calls: state => state.profile.alt_calls,
  use_new_agenda: state => state.profile.use_new_agenda,
  isRespComercial: state => state.profile.chief,
}

export default {
  state,
  actions,
  mutations,
  getters,
}

import api from '..'

export default {
  async getProfile() {
    const { data } = await api.get('/me/')
    if (data.uppercase_text) {
      const d = document.getElementsByTagName('html')[0]
      d.style.textTransform = 'uppercase'
    }
    return data
  },
  async refreshToken(token) {
    const { data } = await api.post(`/token-refresh/`, { token })
    return data
  },
  async obtainToken(creds) {
    const { data } = await api.post(`/token-auth/`, creds)
    return data.token
  },
}
